<template>
  <main>
    <!-- prettier-ignore -->
    <section class="px-2 md:px-0 py-4" v-if="company">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-12-mobile is-8-tablet is-6-desktop">
            <nav class="breadcrumb" aria-label="breadcrumbs">
              <ul>
                <router-link tag="li" :to="{ name: 'home' }"><a>{{ $t('breadcrumb_home') }}</a></router-link>
                <router-link tag="li" :to="{ name: 'companies' }" class="is-hidden-mobile"><a>{{ $t('breadcrumb_companies') }}</a></router-link>
                <router-link tag="li" :to="{ name: 'company', params: { cid } }"><a><company-name :company="company"/></a></router-link>
                <li class="is-active"><a>{{ $t('breadcrumb_company_settings') }}</a></li>
                <router-link tag="li" :to="{ name: 'company-extensions', params: { cid } }"><a>{{ $t('breadcrumb_company_extensions') }}</a></router-link>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </section>
    <section class="px-2 md:px-0 py-4" v-if="company">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-12-mobile is-8-tablet is-6-desktop">
            <h1 class="title is-3">{{ $t('company_extensions') }}</h1>
            <h2 class="subtitle is-4">{{ $t('company_extensions_help') }}</h2>
            <div class="content">
              <p v-for="ext in extentionsAvailable" :key="ext">
                <label class="checkbox w-full py-1 hover:bg-gray-200">
                  <div class="flex">
                    <div class="p-2">
                      <input
                        type="checkbox"
                        v-model="extentionsSelected"
                        :value="ext"
                        :disabled="disabled"
                      />
                    </div>
                    <div class="p-2">
                      <div>{{ $t('company_extension_' + ext) }}</div>
                      <div class="help">
                        {{ $t('company_extension_' + ext + '_help') }}
                      </div>
                    </div>
                  </div>
                </label>
              </p>
            </div>
            <update-info :value="company" />
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import company from './company';
import CompanyName from '@/components/CompanyName';
import UpdateInfo from '@/components/UpdateInfo';

export default {
  name: 'CompanyExtensions',
  mixins: [company],
  components: { CompanyName, UpdateInfo },
  computed: {
    ...mapGetters('perms', ['isManagerRW']),
    disabled() {
      return !this.isManagerRW;
    },
    extentionsAvailable() {
      const available = ['overlaps', 'ltr15', 'pause30'];
      const companyAvailable = this.company.extensionsAvailable;
      if (companyAvailable) {
        return [...available, ...companyAvailable];
      } else {
        return available;
      }
    },
    extentionsSelected: {
      get: function () {
        return this.company.extensions;
      },
      set: function (values) {
        this.fieldSet({ field: 'extensions', value: values });
      },
    },
  },
  async beforeRouteLeave(from, to, next) {
    await this.send({ clear: true });
    next();
  },
  methods: {
    ...mapActions('company', ['fieldSet', 'send']),
  },
};
</script>
